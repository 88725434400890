import { ResourceType } from "src/resources/resources.constants"
import * as z from "zod"
import { getYoutubeChannelTypeAndId } from "../src/providers/youtube/getChannelMetadata"

export const filterEmptyKeyValues = (object: any) => {
  return Object.entries(object)
    .filter(([_, v]) => v != null && v !== "")
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
}

export const getHostname = (url: string) => {
  try {
    return new URL(url).hostname.replace("www.", "")
  } catch (_) {
    return null
  }
}

export const validateUrl = (s: string) => {
  try {
    new URL(s)
    return true
  } catch (_) {
    return false
  }
}

export const capitalize = (s: string) => {
  return s && s.length ? s.charAt(0).toUpperCase() + s.slice(1) : ""
}

export const lowercaseFirstLetter = (s: string) => {
  return s && s.length ? s.charAt(0).toLowerCase() + s.slice(1) : ""
}

export const getMax = (object: any) => {
  return Object.keys(object).filter((x) => {
    return object[x] === Math.max.apply(null, Object.values(object))
  })
}

export const classNames = (...classes: (string | boolean | undefined)[]) => {
  return classes.filter(Boolean).join(" ")
}

export const checkIsToday = (date: Date) => {
  const today = new Date()
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
}

export const checkIsYesterday = (date: Date) => {
  const today = new Date()
  return (
    date.getDate() + 1 === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
}

export const parseBoolean = (booleanString?: string | boolean): boolean => {
  return booleanString === "true"
}

export const getResourceType = (url: string, doc: Document) => {
  const { origin } = new URL(url)
  if (origin.indexOf("amazon.") > -1) {
    const category = doc.getElementById("nav-subnav")?.dataset.category
    if (category === "books") {
      return ResourceType.BOOK
    } else {
      return ResourceType.OTHER
    }
  } else if (origin.indexOf("goodreads.com") > -1) {
    return ResourceType.BOOK
  } else if (
    ["youtube.com", "youtu.be", "vimeo.com", "ted.com", "m.youtube.com"].some(
      (hostname) => origin.indexOf(hostname) > -1
    )
  ) {
    return ResourceType.VIDEO
  } else if (
    ["classcentral.com", "coursera.org", "edx.org", "futurelearn.", "udemy.", "kadenze."].some(
      (hostname) => origin.indexOf(hostname) > -1
    )
  ) {
    return ResourceType.COURSE
  } else if (["open.spotify.com"].some((hostname) => origin.indexOf(hostname) > -1)) {
    return ResourceType.PODCAST
  } else {
    return ResourceType.OTHER
  }
}

export const isValidEmail = (text: string): boolean => {
  try {
    z.string().email().parse(text)
    return true
  } catch (e) {
    return false
  }
}

export const getInitials = (name: string) =>
  name
    .match(/\b(\w)/g)
    ?.join("")
    .substring(0, 2)
    .toUpperCase() || "N/A"

export const getExtension = (name: string) => {
  const matches = name.match(/(?:\.([^.]+))?$/)
  return matches ? matches[1] : null
}

export const uniqByKeepFirst = (a: any[], key: string) => {
  let seen = new Set()
  return a.filter((item) => {
    let k = item[key]
    return seen.has(k) ? false : seen.add(k)
  })
}

export const titleCase = (str: string) => {
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
}

export const withHttp = (url: string): string =>
  !/^https?:\/\//i.test(url) ? `http://${url}` : url

export const levenshteinDistance = (a: string, b: string): number => {
  // Create a 2D array to store the distances
  let distances = new Array(a.length + 1)
  for (let i = 0; i <= a.length; i++) {
    distances[i] = new Array(b.length + 1)
  }

  // Initialize the first row and column
  for (let i = 0; i <= a.length; i++) {
    distances[i][0] = i
  }
  for (let j = 0; j <= b.length; j++) {
    distances[0][j] = j
  }

  // Fill in the rest of the array
  for (let i = 1; i <= a.length; i++) {
    for (let j = 1; j <= b.length; j++) {
      if (a[i - 1] === b[j - 1]) {
        distances[i][j] = distances[i - 1][j - 1]
      } else {
        distances[i][j] =
          Math.min(distances[i - 1][j], distances[i][j - 1], distances[i - 1][j - 1]) + 1
      }
    }
  }

  // Return the final distance
  return distances[a.length][b.length]
}

export const cleanAmazonUrl = (url: string): string => {
  const pattern = /https?:\/\/www\.amazon\.com\/(?:.+\/)?(?:dp|gp\/product)\/([^/?]+)/i
  const match = url.match(pattern)
  if (match && match[1]) {
    return `https://www.amazon.com/dp/${match[1]}`
  } else {
    return url
  }
}

export const sanitizeUrl = (url: string, keepQs: string[] = []): string => {
  // Parse the URL
  const parsedUrl = new URL(url)
  // Filter the query parameters
  const filteredParams = new URLSearchParams()
  for (const [key, value] of Array.from(parsedUrl.searchParams.entries())) {
    if (keepQs.includes(key)) {
      filteredParams.append(key, value)
    }
  }

  // Reconstruct the URL without the filtered query parameters and without the fragment
  const sanitizedUrl = new URL(parsedUrl.origin + parsedUrl.pathname)
  sanitizedUrl.search = filteredParams.toString()
  sanitizedUrl.hash = parsedUrl.hash

  return sanitizedUrl.toString()
}

export const getUrlVersions = (url: string): string[] => {
  // Check if the URL ends with a slash
  const hasTrailingSlash = url.endsWith("/")

  // Construct the URL without a trailing slash
  const urlWithoutSlash = hasTrailingSlash ? url.slice(0, -1) : url

  // Construct the URL with a trailing slash
  const urlWithSlash = hasTrailingSlash ? url : url + "/"

  // Return both versions in an array
  return [urlWithoutSlash, urlWithSlash]
}

export function getIsYouTubeUrl(url: string): boolean {
  const regex =
    /^(?:(?:https?:)?\/\/)?(?:(?:www|m)\.)?(?:youtube\.com|youtu\.be)(?:\/(?:[\w\-]+\?v=|embed\/|v\/|shorts\/|live\/)?)([\w\-]+)(?:\S+)?$/
  return regex.test(url)
}

const extractVideoId = (url: string): string => {
  const pattern =
    /(?:youtube\.com\/(?:watch\?(?:.*&)?v=|embed\/|shorts\/)|youtu\.be\/)([0-9A-Za-z_-]{11})/
  const match = url.match(pattern)
  if (match && match[1]) {
    return match[1]
  } else {
    throw new Error("Could not find video ID for YouTube URL!")
  }
}

export const simplifyUrl = (url: string): string[] => {
  if (!url || url.length === 0) {
    return []
  }
  const isUrl = validateUrl(url)
  if (!isUrl) {
    return []
  }

  if (url.includes("amazon.com")) {
    return [cleanAmazonUrl(url)]
  } else if (getIsYouTubeUrl(url)) {
    const result = getYoutubeChannelTypeAndId(url)
    if (result) {
      return [url]
    }
    // Extract video ID
    const videoId = extractVideoId(url)
    if (videoId) {
      return [`https://www.youtube.com/watch?v=${videoId}`]
    }

    const QUERY_WHITE_LIST = ["v", "title", "list"]

    // url = sanitizeUrl(url, QUERY_WHITE_LIST)
    const parsedUrl = new URL(url)
    // Extract params from url
    const params = new URLSearchParams(parsedUrl.search)
    const keys = Array.from(params.keys())
    let filteredKeys = keys.filter((key) => QUERY_WHITE_LIST.includes(key))
    if (filteredKeys.includes("v")) {
      // Remove the list param if the video id is present
      filteredKeys = filteredKeys.filter((key) => key !== "list")
    }
    const filteredParams = new URLSearchParams()
    filteredKeys.forEach((key) => {
      filteredParams.append(key, params.get(key)!)
    })
    url = `${url.split("?")[0]}?${filteredParams.toString()}`
    return [url]
  } else {
    return getUrlVersions(url)
  }
}

export const formatDuration = (duration: number): string => {
  const hours = Math.floor(duration / 60)
  const minutes = duration % 60
  return `${hours > 0 ? `${hours}h ` : ""}${minutes}m`
}
